<template>
  <v-container
    id="clientes"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="colorSnak"
      >
        {{ text }}
        <v-btn
          color="black"
          text
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </v-snackbar>
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Buscar cliente
            </div>

            <div class="subtitle-1 font-weight-light">
              Ingrese los datos
            </div>
          </template>

          <v-form
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="clienteItem.dni"
                    :rules="dniRules"
                    label="DNI/NIE/PASSPORT"
                    required
                    @blur="verificarCliente(clienteItem.dni)"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="indigo"
                    @click="verificarCliente(clienteItem.dni)"
                  >
                    <v-icon
                      dark
                    >
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="clienteItem.nombre"
                    :rules="nameRules"
                    label="Nombre"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="clienteItem.primer_apellido"
                    :rules="apeRules"
                    label="Primer Apellido"
                    class="purple-input"
                    @keydown.space.prevent
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="clienteItem.segundo_apellido"
                    :rules="apeRules"
                    label="Segundo Apellido"
                    class="purple-input"
                    @keydown.space.prevent
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="clienteItem.correo"
                    :rules="emailRules"
                    label="Correo electronico"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="clienteItem.telefono"
                    :rules="telefonoRules"
                    label="Teléfono"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="clienteItem.ciudad"
                    :rules="ciudadRules"
                    label="Ciudad"
                    class="purple-input"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <VueSignaturePad
                    ref="signaturePad"
                    width="80%"
                    height="300px"
                    :custom-style="estilacho"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    scrollable
                    max-width="400"
                  >
                    <v-card>
                      <v-card-title>
                        <v-img
                          src="@/assets/logograssy.png"
                          style="width: 150px; margin: auto;"
                        />
                        <div style="text-align: center; text-decoration: underline;">
                          <b>POLITICA TRATAMIENTO DE DATOS</b><br>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        Sus datos personales serán tratados por GRASSY S.A. con CIF A28550127 y domicilio en Gran Vía, 1,
                        28013 Madrid. La finalidad de la recogida y tratamiento de datos es gestionar la relación contractual o
                        precontractual con usted, sus datos serán utilizados para la correcta ejecución del contrato o precontrato,
                        también utilizaremos sus datos para mantenerle informado de nuestros productos o servicios. Las bases
                        que nos legitiman para el tratamiento de sus datos personales son:
                        (i) la necesidad de ejecutar un contrato.
                        (ii) cumplir nuestras obligaciones legales, incluyendo las relativas a la prevención del blanqueo de
                        capitales y de financiación del terrorismo.
                        (iii) interés legítimo de GRASSY S.A..
                        Sus datos podrán comunicarse a terceros cuando sea necesario para el cumplimiento y mantenimiento de
                        la relación contractual, cuando exista una obligación legal, y en los supuestos en que contemos con su
                        consentimiento expreso para la comunicación.
                        Sus datos serán conservados durante el plazo necesario para atender las correspondientes
                        responsabilidades legales. Le informamos de que usted puede ejercer sus derechos de acceso,
                        rectificación o supresión, oposición, limitación a su tratamiento, y portabilidad, en los términos previstos en
                        la normativa de protección de datos, dirigiéndose por escrito a Gran Vía, 1, 28013 Madrid o por email a
                        dpo@grassy.es. Le recordamos que en todo momento usted tiene derecho a presentar reclamación ante
                        la Agencia Española de Protección de datos (www.aepd.es/).
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="green darken-1"
                          text
                          @click="dialog = false"
                        >
                          Acepto
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-checkbox
                    v-model="checkbox"
                    :rules="[v => !!v || 'Debes estar de acuerdo!']"
                    required
                  >
                    <template v-slot:label>
                      <div>
                        Estas de acuerdo?
                        <a
                          href="#"
                          @click="dialog = true"
                        >
                          Ver la politica de datos.
                        </a>
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="text-right"
                >
                  <v-btn
                    color="indigo"
                    class="mr-2"
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer />
                <v-btn
                  color="red"
                  class="mr-2"
                  @click="undo"
                >
                  Deshacer firma
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        dialog: false,
        checkbox: false,
        valid: true,
        lazy: false,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        showSignature: false,
        editedIndex: 0,
        estilacho: {
          border: 'black 3px solid',
          'margin-right': 'auto',
          'margin-left': 'auto',
        },
        clienteItem: {},
        dniRules: [
          v => !!v || 'El DNI/NIE/PASSPORT es requerido',
        ],
        nameRules: [
          v => !!v || 'El nombre es requerido',
        ],
        apeRules: [
          v => !!v || 'El apellido es requerido',
        ],
        emailRules: [
          v => !!v || 'El correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo electrónico valido',
        ],
        telefonoRules: [
          v => !!v || 'El teléfono es requerido',
        ],
        ciudadRules: [
          v => !!v || 'La ciudad es requerida',
        ],
      }
    },
    methods: {
      limpiar () {
        this.clienteItem.id = 0
        // this.clienteItem.dni = ''
        this.clienteItem.nombre = ''
        this.clienteItem.primer_apellido = ''
        this.clienteItem.segundo_apellido = ''
        this.clienteItem.correo = ''
        this.clienteItem.telefono = ''
        this.clienteItem.ciudad = ''
        this.$refs.signaturePad.clearSignature()
        this.$refs.signaturePad.clearCacheImages()
        this.editedIndex = 0
        this.checkbox = false
      },
      verificarCliente (dni) {
        if (dni && dni !== '') {
          axios.get('/clientesbyiden/' + dni)
            .then(response => {
              this.editedIndex = 1
              this.clienteItem = response.data
              var splitLastname = response.data.apellidos.split(' ')
              this.clienteItem.primer_apellido = (splitLastname[0]) ? splitLastname[0].charAt(0).toUpperCase() + splitLastname[0].slice(1) : ''
              this.clienteItem.segundo_apellido = (splitLastname[1]) ? splitLastname[1].charAt(0).toUpperCase() + splitLastname[1].slice(1) : ''
              this.$refs.signaturePad.resizeCanvas()
              this.$refs.signaturePad.fromDataURL(this.clienteItem.firma)
            })
            .catch(error => {
              if (error.response) {
                this.editedIndex = 0
                this.text = 'Cliente no encontrado'
                this.colorSnak = 'info'
                this.snackbar = true
                this.limpiar()
              } else if (error.request) {
                console.log(error.request)
              } else {
                console.log('Error', error.message)
              }

              console.log(error.config)
            })
            .then(() => {
              this.$refs.signaturePad.resizeCanvas()
              this.showSignature = true
            })
        } else {
          this.text = 'Antes debe ingresar una identificación'
          this.colorSnak = 'info'
          this.snackbar = true
        }
      },
      undo () {
        this.$refs.signaturePad.undoSignature()
      },
      save () {
        if (this.$refs.form.validate()) {
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
          if (isEmpty) {
            this.text = 'Antes debe ingresar una firma'
            this.colorSnak = 'info'
            this.snackbar = true
          } else {
            this.clienteItem.firma = data
            if (this.editedIndex === 0) {
              axios.post('/clientes', this.clienteItem)
                .then(response => {
                  this.clienteItem.id = response.data.id
                  this.text = 'Cliente creado correctamente'
                  this.colorSnak = 'success'
                  this.snackbar = true
                  this.editedIndex = 1
                  this.limpiar()
                  this.clienteItem.dni = ''
                })
                .catch(error => {
                  if (error.response) {
                    this.text = 'El cliente no puede ser creado en este momento'
                    this.colorSnak = 'error'
                    this.snackbar = true
                  } else if (error.request) {
                    console.log(error.request)
                  } else {
                    console.log('Error', error.message)
                  }

                  console.log(error.config)
                })
            } else {
              axios.put('/clientes/' + this.clienteItem.id, this.clienteItem)
                .then(response => {
                  console.log(response)
                  this.text = 'Cliente modificado correctamente'
                  this.snackbar = true
                  this.editedIndex = 1
                })
            }
          }
        }
      },
    },
  }
</script>
